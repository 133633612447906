import React from 'react';

const Footer = () => {
  return (
    <footer className='bg-primary-darker py-[24px] text-white text-sm text-center'>
      <div className='container mx-auto'>Copyright ©2022 All rights reserved | This template is made with ❤ by <a href="https://hackerbro.in/" target="_blank" rel="noopener noreferrer"> Hacker Bro Technologies</a></div>
    </footer>
  );
};

export default Footer;
